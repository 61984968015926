import "./Keyboard.css";
import Key from "./Key.js";

function Keyboard() {
  return (
    <div className="keyboard">
      <Key internal="q" />
      <Key internal="w" />
      <Key internal="e" />
      <Key internal="r" />
      <Key internal="t" />
      <Key internal="y" />
      <Key internal="u" />
      <Key internal="i" />
      <Key internal="o" />
      <Key internal="p" />
      <Key internal="a" />
      <Key internal="s" />
      <Key internal="d" />
      <Key internal="f" />
      <Key internal="g" />
      <Key internal="h" />
      <Key internal="j" />
      <Key internal="k" />
      <Key internal="l" />
      <Key internal="Backspace" icon="backspace" />
      <Key className="bad" internal="Escape" icon="delete" />
      <Key internal="z" />
      <Key internal="x" />
      <Key internal="c" />
      <Key internal="v" />
      <Key internal="b" />
      <Key internal="n" />
      <Key internal="m" />
      <Key className="good" internal="Enter" icon="send" />
      <div className="fill"></div>
    </div>
  );
}

export default Keyboard;
