import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";

import Tile from "./Tile";
import Keyboard from "./Keyboard";
import Key from "./Key";
import Logo from "./Logo";
import "./Game.css";

function Game({ mode }) {
  const [tiles, setTiles] = useState([]);
  const [rounds, setRounds] = useState([{}]);
  const [attempts, setAttempts] = useState(0);
  const [gameLost, setGameLost] = useState(false);
  const [gameWon, setGameWon] = useState(false);
  const [shake, setShake] = useState(false);
  const inputRef = useRef(null);

  const apiUrl = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "http://localhost:5000/api";

  const { seed } = useParams();

  const [activeSeed, setActiveSeed] = useState(seed);
  const [inputText, setInputText] = useState("");

  const newGame = () => {
    let url = `${apiUrl}/get_nouns`;
    url += mode === "daily" ? "_daily" : "";
    let payload = {
      amount: 12,
      ...(seed && { seed: seed }),
    };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((data) => {
        setAttempts(0);
        setGameLost(false);
        setGameWon(false);
        setActiveSeed(data.seed);
        setRounds([]);
        setTiles(
          data.nouns.map((noun, index) => ({
            noun: noun,
            active: false,
            moved: false,
            role: data.roles[index],
          })),
        );
      })
      .catch((error) => console.error("Error fetching nouns:", error));
  };

  useEffect(() => {
    newGame();
  }, [apiUrl]);

  useEffect(() => {
    inputRef.current.focus();
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  });

  const shakeInput = () => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 200);
  };

  const handleKeyDown = (e) => {
    inputRef.current.focus();
    if (!gameWon && !gameLost) {
      const isLetter = /^[a-zA-Z]$/.test(e.key);
      if (isLetter) {
        if (inputText.length > 24) {
          shakeInput();
        } else {
          setInputText(inputText + e.key);
        }
      } else if (e.key === "Backspace") {
        if (inputText.length > 0) {
          setInputText(inputText.slice(0, -1));
        } else {
          shakeInput();
        }
      } else if (e.key === "Escape") {
        setInputText("");
      } else if (e.key === "Enter") {
        if (gameLost || gameWon || inputText === "") {
          shakeInput();
        } else {
          handleRankNouns();
        }
      }
    } else {
      if (e.key === "_") {
        newGame();
      } else if (e.key === "+") {
        let r = rounds.map((round, i) => {
          let emojis = "🟩".repeat(round.hits);
          if (i === rounds.length - 1) {
            if (gameWon) {
              emojis = emojis ? emojis.slice(0, -2) + "🟨⬛" : "🟨";
            } else if (gameLost) {
              emojis += "🟪";
            }
          } else {
            emojis += "⬜";
          }
          if (round.hits <= 5) {
            emojis += "⬛".repeat(5 - round.hits);
          }
          let guess = round.guess;
          guess = "*".repeat(guess.length).split("").join(" ");
          return `${emojis} ${guess}`;
        });
        let toCopy = r.join("\n");
        toCopy += `\n\n${window.location.host}`;
        if (mode === "daily") {
          toCopy += "/daily";
        } else if (activeSeed) {
          toCopy += `/${activeSeed}`;
        }
        navigator.clipboard.writeText(toCopy);
      }
    }
  };

  const handleRankNouns = () => {
    let oldTiles = [...tiles];
    fetch(`${apiUrl}/rank_nouns`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        input: inputText,
        nouns: oldTiles.map((tile) => tile.noun),
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setRounds([
          ...rounds,
          {
            guess: inputText,
            hits: moveNouns(data.ranks),
          },
        ]);
        setAttempts(attempts + 1);
        setInputText("");
      })
      .catch((error) => shakeInput());
  };

  const moveNouns = (ranked) => {
    let activated = 0;
    let hits = 0;
    let oldTiles = [...tiles];
    for (let i = 0; i < ranked.length; i++) {
      const tileIndex = tiles.findIndex((tile) => tile.noun === ranked[i]);
      const tile = tiles[tileIndex];
      if (!tile.active) {
        activated++;
        setTimeout(() => {
          setTiles((prevTiles) =>
            prevTiles.map((prevTile, j) =>
              tile.noun === prevTile.noun
                ? { ...prevTile, moved: true }
                : prevTile,
            ),
          );
        }, activated * 300);
        oldTiles[tileIndex].active = true;
        if (tile.role === "neutral") {
          return hits;
        } else if (tile.role === "bad") {
          setGameLost(true);
          return hits;
        } else {
          hits++;
          const win = oldTiles.every((t) => t.role !== "good" || t.active);
          if (win) {
            setGameWon(true);
            return hits;
          }
        }
      }
    }
  };

  const getFillClass = (hits, i) => {
    if (i === rounds.length - 1) {
      return gameWon
        ? "win"
        : gameLost
        ? "lose"
        : hits > 0
        ? "good"
        : "neutral";
    } else {
      return hits > 0 ? "good" : "neutral";
    }
  };

  return (
    <div className="app">
      <div className="game-container">
        <div className="title-container">
          <Logo />
          <div className={`mode${mode === "daily" ? " daily" : ""}`}>
            <span className="material-symbols-rounded">star</span>
          </div>
        </div>
        <div className="grid">
          {tiles.map((tile, i) => (
            <Tile
              key={i}
              noun={tile.noun}
              moved={tile.moved}
              role={tile.role}
            />
          ))}
        </div>
        <div className="input-container">
          <div
            ref={inputRef}
            className={`input${inputText ? "" : " empty"}${
              shake ? " shake" : ""
            }${gameLost ? " lost" : ""}${gameWon ? " won" : ""}`}
          >
            {gameLost
              ? "Game Over"
              : gameWon
              ? "You win!"
              : inputText
              ? inputText
              : "Type something..."}
          </div>
          <div className="counter">x{attempts}</div>
        </div>
      </div>
      {gameWon || gameLost ? (
        <div className="info-panel">
          <div className="info-text">
            {[0, 1, 2, 3, 4, 5].map((item, i) => [
              <div
                key={`fill-${i}`}
                className={`fill ${
                  rounds[i] ? getFillClass(rounds[i].hits, i) : ""
                }`}
              >
                {rounds[i] ? rounds[i].hits : "?"}
              </div>,
              <div
                key={`guess-${i}`}
                title={rounds[i] ? rounds[i].guess : ""}
                className="info-guess"
              >
                <p>{rounds[i] ? rounds[i].guess : ""}</p>
              </div>,
            ])}
          </div>
          <div className="info-buttons">
            {gameWon ? (
              <div className="fill win">:-)</div>
            ) : gameLost ? (
              <div className="fill lose">:-(</div>
            ) : (
              <div className="fill"></div>
            )}
            <Key internal="+" icon="content_copy" />
            {mode !== "daily" ? (
              <Key internal="_" icon="replay" />
            ) : (
              <div className="fill"></div>
            )}
          </div>
        </div>
      ) : (
        <Keyboard />
      )}
    </div>
  );
}

Game.defaultProps = {
  mode: "default",
};

export default Game;
