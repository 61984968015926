import React, { useEffect, useState } from "react";

function Key({ className, internal, icon }) {
  const [active, setActive] = useState(false);
  const [press, setPress] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === internal) {
      setActive(true);
      setTimeout(() => {
        setActive(false);
      }, 100);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setActive]);

  const handleClick = () => {
    if (internal) {
      const eventDown = new KeyboardEvent("keydown", {
        key: internal,
      });
      document.dispatchEvent(eventDown);
      setPress(true);
      setTimeout(() => {
        setPress(false);
      }, 100);
    }
  };

  return (
    <div
      className={`key ${className} ${active ? " active" : ""} ${
        press ? " press" : ""
      }`}
      onClick={handleClick}
    >
      {icon ? (
        <span className="material-symbols-rounded">{icon}</span>
      ) : (
        internal.toUpperCase()
      )}
    </div>
  );
}

Key.defaultProps = {
  className: "",
  icon: false,
};

export default Key;
