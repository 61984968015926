import "./Logo.css";

function Logo() {
  return (
    <a className="logo" href="http://lllil.li/work">
      Similar <span className="material-symbols-rounded">stack</span>'s
    </a>
  );
}

export default Logo;
