import React from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Game from "./Game";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Game />} />
      <Route path="/daily" element={<Game mode="daily" />} exact />
      <Route path="/:seed" element={<Game />} />
    </Routes>
  </Router>
);

export default App;
