import './Tile.css';

function Tile({ noun, moved, role }) {

  return (
    <div className={`tile${moved ? ' moved ': ' '}${role ? role : ''}`}>
      { noun }
    </div>
  );
}

export default Tile;
